
import { defineComponent } from 'vue'
import LoggedMenu from './components/LoggedMenu.vue';

export default defineComponent({
  name: 'App',

  components: {
    LoggedMenu,
  },

  data () {
    return {
      //
    }
  },
})
