import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "font-weight-bold text-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_fade_transition = _resolveComponent("v-fade-transition")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_v_table = _resolveComponent("v-table")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!

  return (_openBlock(), _createBlock(_component_v_expansion_panels, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_expansion_panel, {
        "bg-color": _ctx.color || ''
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_expansion_panel_title, null, {
            default: _withCtx(({ expanded }) => [
              _createVNode(_component_v_row, { "no-gutters": "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: !expanded ? 2 : 12,
                    class: "d-flex justify-start"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(expanded ? _ctx.fullTitle : _ctx.title), 1)
                    ]),
                    _: 2
                  }, 1032, ["cols"]),
                  (!expanded)
                    ? (_openBlock(), _createBlock(_component_v_spacer, { key: 0 }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_col, {
                    cols: !expanded ? 5 : 12,
                    class: "text-grey"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_fade_transition, { "leave-absolute": "" }, {
                        default: _withCtx(() => [
                          expanded
                            ? (_openBlock(), _createElementBlock("span", {
                                key: "0",
                                class: _normalizeClass(!_ctx.globalStatus ? 'text-red' : '')
                              }, _toDisplayString(!_ctx.globalStatus ? _ctx.allErrorMessage.join('\n') : 'Everything is fine'), 3))
                            : (_openBlock(), _createElementBlock("span", {
                                key: "1",
                                class: _normalizeClass(!_ctx.globalStatus ? 'text-red' : 'text-green')
                              }, _toDisplayString(!_ctx.globalStatus ? _ctx.firstErrorMessage : 'OK'), 3))
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["cols"])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          }),
          _createVNode(_component_v_expansion_panel_text, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, {
                justify: "space-around",
                "no-gutters": ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_table, { density: "compact" }, {
                    default: _withCtx(() => [
                      _createElementVNode("tbody", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(((_ctx.apisData?.data || []) as ServerStatusResponse[]), (el) => {
                          return (_openBlock(), _createElementBlock("tr", {
                            key: el.api
                          }, [
                            _createElementVNode("td", _hoisted_1, _toDisplayString(_ctx.extraerTitulo(el.api)), 1),
                            _createElementVNode("td", {
                              class: _normalizeClass(["text-left text-black", el.status.status === null ? 'text-orange' : !el.status.status ? 'text-red' : 'text-green'])
                            }, _toDisplayString(el.status.status === null ? 'Unknown' : !el.status.status ? 'Down' : 'Up'), 3)
                          ]))
                        }), 128))
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["bg-color"])
    ]),
    _: 1
  }))
}