export const isValidDate = (dateString: string) => {
    return /^[0-9]{4}-[0-9]{2}-[0-9]{2}( [0-9]{2}:[0-9]{2}.*)?/.test(dateString) && !isNaN(Date.parse(dateString));
};

export const isArray = (value: any) => {
    return typeof value === 'object' &&  Array.isArray(value);
}

export const isEmptyObject = (value: any) => Object.keys(value).length === 0 && value.constructor === Object;

export const toCamelCase = (text: string, underscoreToSpace=false) => {
    text = text.toString();
    if (underscoreToSpace) { text = text.toString().replace('_',' '); }
    return text.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
}

  // Form Validation Rules
export const rules = {
    required: (value: any) => {
      if (!value) return 'Required field.';
      return true;
    },
    isEmail: function(value: string) {
      if (!(value && /[A-Za-z0-9\.\-_]{3,}@([A-Za-z0-9-_]{3,}\.)+[A-Za-z]{2,3}/.test(value.toString()))) return 'Enter a valid email';
      return true;
    },
    minLength: (len: number) => (value: string) => {
      if (!(value && value.length >= len)) return `Must have at least ${len} characters.`;
      return true;
    },
  };

  export class cloneable {
    public static deepCopy<T>(source: T): T {
      return Array.isArray(source)
      ? source.map(item => this.deepCopy(item))
      : source instanceof Date
      ? new Date(source.getTime())
      : source && typeof source === 'object'
            ? Object.getOwnPropertyNames(source).reduce((o, prop) => {
               Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(source, prop)!);
               o[prop] = this.deepCopy((source as { [key: string]: any })[prop]);
               return o;
            }, Object.create(Object.getPrototypeOf(source)))
      : source as T;
    }
  }