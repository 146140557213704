
    import { ApiStatusResponse } from "@/types";
    import { VNodeChild } from "vue";
    import { defineComponent } from "vue"
    import { useDisplay } from 'vuetify'
    import FromNow from "@/components/FromNow.vue";
    import SupportObject from "./SupportObject.vue";
    import SupportTarget from "@/types/SupportTarget";
    const defaultPagesVisible = 5;

    export default defineComponent({
        name: 'SearchIterator',
        props: {
            inputData: {
                type: [Array],
                required: true,
            }, 
            title: String, 
            color: String,
            // itemsPerPageArray: { 
            //     type: Array,
            //     required: false,
            //     default() {
            //         return [
            //             {value: 3, title: '3'}, 
            //             {value: 5, title: '5'},
            //             {value: 10, title: '10'},
            //             {value: 20, title: '20'},
            //             {value: -1, title: 'All'}
            //         ];
            //     },
            // },
            // itemsPerPageDefault: {
            //     type: Number,
            //     required: false,
            //     default: 3,
            // },
        },
        components: {
            FromNow,
            SupportObject,
        },
        data () {
            return {
                expanded: [],
                pagination: {},
                singleExpand: false,
                itemsPerPageArray: [
                    {value: 3, title: '3'}, 
                    {value: 5, title: '5'},
                    {value: 10, title: '10'},
                    {value: 20, title: '20'},
                    {value: -1, title: 'All'}
                ],
                itemsPerPage: 3,
                // itemsPerPage: this.itemsPerPageDefault,
                page: 1,
                searchText: '',
                selectedItems: [] as any[],
                searchResult: [] as any[],
                searchResultCount: -1,
                sortKey: 'id',
                sortOrder: 'asc',
                columnsSelected: ['id','name','last_name','created_at','profile_type','status','email', 'card_type', 'card_id'] as any[],
                columnsAll: [] as any[],
                keys: [
                'name',
                'id',
                'email',
                'username',
                'profile_type',
                'status',
                'created_at',
                'updated_at',
                ],
            }
        },
        watch: {
            inputData: {
                immediate: true, 
                handler(newVal, oldVal) {
                    console.log('Prop changed: ', newVal, ' | was: ', oldVal)
                }
            },
        },
        computed: {
            numberOfPages (): number {
                return Math.ceil((this.searchResultCount >=0 ? this.searchResultCount : this.searchData.length) / this.itemsPerPage)
            },
            sortBy(): {key: string, order: string}[] {
                return [{
                    key: this.sortKey,
                    order: this.sortOrder,
                }]
            },
            searchData() {
                console.log(this.searchResult,this.inputData);
                return this.inputData;
            },
            pagesVisible() {
                return this.xs ? 1 : this.smAndDown ? 3 : defaultPagesVisible;
            },
            columnListVisible() {
                return (this.smAndDown ? 2 : 6);
            },
            columnsMap() {
                let cols = (this.inputData && this.inputData.length > 0) ? Object.keys((this.inputData as Object[])[0]) : ['id','name'];
                return cols
                .filter((elem: any) => (!['pinned'].includes(elem)))
                .map((col: string, idx: number, arr: string[]) => ({
                    key: col,
                    title: col.replaceAll('_',' '), 
                    align: col=='id'?'start':'end', 
                    sortable: this.keys?.includes(col) || true, 
                }))
                .reduce((prev:any,curr:any) => { return {...prev, [curr.key]: curr} },{});
            },
            // expanded() {
            //     return this.searchData.map((v: any) => (v.expanded || ''));
            // },
        },
        methods: {
            nextPage () {
                if (this.page + 1 <= this.numberOfPages) this.page += 1
            },
            prevPage () {
                if (this.page - 1 >= 1) this.page -= 1
            },
            getPagination(pagination : any) {
                console.log('PaginationEvent:',pagination.itemsLength) // length of filtered/searched items in Vuetify data-table
                this.searchResultCount = pagination.itemsLength;
            },
            getFiltered(e: any){
                console.log('FilteredEvent:',e) //output the filtered items
                this.selectedItems
            },
            
            updateModelValue(selectedItems: any) {
                this.selectedItems = selectedItems;
                console.log('SelectedItems',this.selectedItems)
            },
            updateEvent(e: any){
                console.log('AnyEvent:',e) //output the filtered items
            },
            getItemTarget(item:any) {
                const t = new SupportTarget(`${SupportTarget.BASE_URL}/account/${item.raw.id}/full`,'Account Full Info');
                t.id = item.raw.id;
                return t;
            },
        },
        setup() {
            const { xs, smAndDown } = useDisplay();
            return { xs, smAndDown };
        },
        mounted() {
            // this.columnsSelected = this.columnsMap;
            console.log('attrs',this.$attrs)
            console.log('SelectedItems',this.selectedItems)
            // this.$set('searchData', this.props.data);
            this.selectedItems = this.inputData.filter((elem:any) => elem.pinned)
            this.columnsAll = Object.values(this.columnsMap);
            if (!this.columnsSelected) {
                this.columnsSelected = this.columnsAll;
            } else {
                // this.columnsSelected = this.columnsAll.filter(elem => this.columnsSelected.includes(elem.key));
                this.columnsSelected = this.columnsSelected.map((elem:string) => this.columnsMap[elem]);
            }
        },
        created () {
        },
    });
