import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_app_bar_title = _resolveComponent("v-app-bar-title")!
  const _component_LoggedMenu = _resolveComponent("LoggedMenu")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_app_bar, {
        app: "",
        color: "deep-purple-darken-2"
      }, {
        prepend: _withCtx(() => [
          _createVNode(_component_v_img, {
            alt: "TipTap Logo",
            class: "flex-shrink-1 mr-2",
            contain: "",
            src: "https://paytiptap.com/wp-content/uploads/2022/11/tiptap-white-768x236.png",
            transition: "scale-transition",
            width: "80"
          })
        ]),
        append: _withCtx(() => [
          _createVNode(_component_LoggedMenu)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_v_app_bar_title, null, {
            default: _withCtx(() => [
              _createTextVNode("Support")
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}