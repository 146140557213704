export * from './Account';
export * from './AdminAccount';
export * from './NonAdminAccount';
export * from './UserProfile';
export * from './SupportTarget';
import { Component } from "vue";

export type ReadOnly<T> = {
    readonly [P in keyof T]: T[P];
}

export type PartialType<T> = {
    [P in keyof T]?: T[P];
}

export type LiteralType<T> = {
    [P in keyof T]: T[P];
}

// build getters for Type properties like = getName: () => string;
export type Getter<T> = {
    [P in keyof T as `get${Capitalize<string & P>}`]: () => T[P]
};

// build async getters for Type properties like = getNameAsync: () => Promise<string>;
export type AsyncGetter<T> = {
    [P in keyof T as `get${Capitalize<string & P>}Async`]: () => Promise<T[P]>;
}


export type fieldData = {
    id?: string,
    type: Component,
    title: string,
    data: Object,
    color: string,
};
  
export class ApiStatusResponse {
    data: ServerStatusResponse[] | String | undefined = [];
    status?: boolean | null = null;
    msg?: string = "";
};
export class ServerStatusResponse {
    api: string = "";
    status: ApiStatusResponse = {data: undefined};
};