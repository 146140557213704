import { cloneable } from "@/plugins/common";

export enum HANDS {
    RIGHT = 0,
    LEFT = 1
};

export enum FINGERS {
    THUMB = 1,
    INDEX = 2,
    MIDDLE = 3,
    RING = 4,
    LITTLE = 5
};

export class FingerStatus {
    public _fill = '';

    constructor(
        public id:number, // format {id_account}.{hand_id}{finger_id}
        readonly status: 'unsetted'|'setted'|'selected' = 'unsetted', 
        public detail: string = '',
        fill: string = '',
    ) {
        this._fill = fill;
    }

    get fill() {
        return this._fill != '' ? this._fill : (
            this.status === 'setted' ? 'green' :
            this.status === 'selected' ? 'red' : 'grey'
        );
    }

    get hand_id(): HANDS {
        let decimalValue = this.id.toString().indexOf(".");
        return decimalValue > 0 ? (parseInt(this.id.toFixed(2).slice(decimalValue+1,decimalValue+1)) as HANDS) : HANDS.RIGHT;
    }

    get finger_id(): FINGERS {
        let decimalValue = this.id.toString().indexOf(".");
        return decimalValue > 0 ? (parseInt(this.id.toFixed(2).slice(decimalValue+2,decimalValue+2)) as FINGERS) : FINGERS.THUMB;
    }

    get hand() {
        return FingerStatus.handFromId(this.hand_id);
    }

    public static fromSupportRow(support: any): FingerStatus {
        const f = new FingerStatus(support.object_id, 'setted');
        f.detail = support.resume || ['Hand',FingerStatus.handFromId(support.hand_id),',',FingerStatus.fingerFromId(support.finger_id),'finger',...(f.status?[`(${f.status})`]:[])].join(' ');
        return f;
    }

    public static idFromFingerIds(account_id:number, hand_id:HANDS, finger_id:FINGERS) {
        return account_id + hand_id/10.0 + finger_id/100.0;
    }
    
    public static handFromId(handId: HANDS) {
        return handId === HANDS.RIGHT ? 'right' : 'left';
    }
    
    public static fingerFromId(fingerId: FINGERS) {
        switch (fingerId) {
        case FINGERS.LITTLE: return 'little';
        case FINGERS.RING: return 'ring';
        case FINGERS.MIDDLE: return 'middle';
        case FINGERS.INDEX: return 'index';
        case FINGERS.THUMB:
        default:
            return 'thumb';
            
        }
    }
}

export interface iFingerprintFingers {
    [FINGERS.THUMB]: FingerStatus;
    [FINGERS.INDEX]: FingerStatus;
    [FINGERS.MIDDLE]: FingerStatus;
    [FINGERS.RING]: FingerStatus;
    [FINGERS.LITTLE]: FingerStatus;
};
export interface iFingerprint extends cloneable {
    [HANDS.LEFT]: iFingerprintFingers;
    [HANDS.RIGHT]: iFingerprintFingers;
};
export interface iFingerprint extends cloneable {
    [HANDS.LEFT]: iFingerprintFingers;
    [HANDS.RIGHT]: iFingerprintFingers;
};

export class BaseFingerprint implements iFingerprint {
    [HANDS.LEFT]: iFingerprintFingers = {
        [FINGERS.THUMB]: new FingerStatus(FingerStatus.idFromFingerIds(0,HANDS.LEFT,FINGERS.THUMB),'unsetted'),
        [FINGERS.INDEX]: new FingerStatus(FingerStatus.idFromFingerIds(0,HANDS.LEFT,FINGERS.INDEX),'unsetted'),
        [FINGERS.MIDDLE]: new FingerStatus(FingerStatus.idFromFingerIds(0,HANDS.LEFT,FINGERS.MIDDLE),'unsetted'),
        [FINGERS.RING]: new FingerStatus(FingerStatus.idFromFingerIds(0,HANDS.LEFT,FINGERS.RING),'unsetted'),
        [FINGERS.LITTLE]: new FingerStatus(FingerStatus.idFromFingerIds(0,HANDS.LEFT,FINGERS.LITTLE),'unsetted'),
    };
    [HANDS.RIGHT]: iFingerprintFingers ={
        [FINGERS.THUMB]: new FingerStatus(FingerStatus.idFromFingerIds(0,HANDS.RIGHT,FINGERS.THUMB),'unsetted'),
        [FINGERS.INDEX]: new FingerStatus(FingerStatus.idFromFingerIds(0,HANDS.RIGHT,FINGERS.INDEX),'unsetted'),
        [FINGERS.MIDDLE]: new FingerStatus(FingerStatus.idFromFingerIds(0,HANDS.RIGHT,FINGERS.MIDDLE),'unsetted'),
        [FINGERS.RING]: new FingerStatus(FingerStatus.idFromFingerIds(0,HANDS.RIGHT,FINGERS.RING),'unsetted'),
        [FINGERS.LITTLE]: new FingerStatus(FingerStatus.idFromFingerIds(0,HANDS.RIGHT,FINGERS.LITTLE),'unsetted'),

    };
}

export const defaultFingerprint = () => new BaseFingerprint();