import { RouteRecordRaw, RouterOptions, createRouter, createWebHistory, onBeforeRouteUpdate, } from 'vue-router';
import LoginView from '@/views/Login.vue';
import ReportView from '@/views/ReportView.vue';
import AppVue from '@/App.vue';
import { useUserSessionStore } from '@/stores/userSession';

const routes: RouteRecordRaw[] = [
  {
    name: 'home',
    path: '/',
    redirect: '/login',
    children: [
      {
        name: 'login',
        path: '/login',
        // redirect: '/',
        component: () => LoginView,
        meta: {
          requiresAuth: false
        },
        alias: ['logout'],
      },
      {
        name: 'report',
        path: '/report',
        // redirect: '/',
        component: () => ReportView,
        meta: {
          requiresAuth: true
        },
      },
    ],
  }
  // {
  //   path: '/user/data',
  //   name: 'userData',
  //   component: () => import('../views/UserDataView.vue')
  // },
  ];

console.log('VUE_APP_BASE_URL:',process.env.VUE_APP_BASE_URL);
console.log('VUE_APP_DOMAIN_PROTO', process.env.VUE_APP_DOMAIN_PROTO);
console.log('VUE_APP_DOMAIN', process.env.VUE_APP_DOMAIN);
console.log('VUE_APP_IMAGES', process.env.VUE_APP_IMAGES);
console.log('VUE_APP_SERVER_URL', process.env.VUE_APP_SERVER_URL);
const router = createRouter({
  history: createWebHistory(),
  routes,
});
  
router.beforeResolve((to:any,from:any) => {
  const store = useUserSessionStore();
  return store.onBeforeRouteUpdate(to,from);
});

export default router
