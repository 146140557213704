export default class SupportTarget {
    public static BASE_URL = '/reports/support';
    constructor(
        public endpoint: string,
        public desc: string,
        public trigger_fields: string[] = [],
        public replace_tag: string = ':id',
        public replace_field: string = 'id',
        public id: string|number = ''
    ) {}
};