// Pinia Store
import { defineStore } from 'pinia'
import { PartialUserProfile } from '@/types/UserProfile'
import { PartialAccount } from '@/types/Account'
import SupportTarget from '@/types/SupportTarget';
import { isArray } from '@/plugins/common';
import { useUserSessionStore } from './userSession';

interface userApiData {
  account?: PartialAccount,
  profile?: PartialUserProfile,
}
type UserApiJoinedData = PartialAccount & PartialUserProfile & {};
type UserApiExtendedData = UserApiJoinedData &  {
  pinned: boolean
};

interface State {
  userData: UserApiExtendedData[],
  supportData: any,
}



const serverUrl = process.env.VUE_APP_SERVER_URL || 'http://localhost:8005';
const headers = (): HeadersInit => {
  const session = useUserSessionStore();
  return {
   'Accept': 'application/json',
   'Content-Type': 'application/json',
     ...(session.auth?.token) ? { Authorization: `Bearer ${session.auth?.token}`} : {},
     'x-secure-identity': session.GET_FINGERPRINT(),
 };
};

const STORE_NAME = 'accountStore';

export const useAccountStore = defineStore(STORE_NAME, {
  // convert to a function
  state: (): State => ({
    userData: [],
    supportData: {},
  }),
  getters: {
  },
  actions: {
    searchAccount(id: string|number|null): Promise<UserApiExtendedData[]> {
      return new Promise((resolve, reject) => {
        if (!id) {
          reject('Required search value missing');
        } else {
          const _url = `${serverUrl}/reports/account/filter?${ new URLSearchParams({
            ...(/^[0-9.,\- ()]+$/.test(id.toString())) && {number: id.toString()} || {text: id.toString()}
          }) }`;
          console.log('QUERY:',_url);
          fetch(_url, {
            // fetch(`${serverUrl}/reports/support/system/paulinagabrielli@gmail.com`, {
            headers: headers(),
            // mode: 'no-cors'
            // method: 'POST',
            // body: JSON.stringify({}),
            //credentials: 'same-origin' 
          })
          .then(res => res.json())
          .then(async response => {
            if (!response.status) {
              reject('Bad request')
              console.error('Bad request',response.msg);
            } else {
              let _data = (response.data?.account) ? response.data.account : response.data;
              console.log('Success request',_data);
              await _data.forEach(async (accountData: any) => {
                this.SET_ACCOUNT_RESULT(accountData);
              });
              // if (response.data!.length == 1) {
              //   resolve(response.data[0]);
              // } else {
                // resolve(response.data);
              // }
              resolve(this.userData);
            }
          })
          .catch(reason => {
            reject('Bad request - error')
            console.error('Bad request - error',reason);
          });
          // fetch(`https://stg-api.paytiptap.com/reports/support/account/${id}`, {headers})
          // .then(res => res.json())
          // .then(response => {
          //   if (!response.status) {
          //     reject('Bad request')
          //     console.error('Bad request',response.msg);
          //   } else {
          //     if (response.data[0]) {
          //       this.SET_ACCOUNT_RESULT(response.data[0]);
          //     } else {
          //       console.log('BAD_PAYLOAD',response);
          //     }
          //     resolve(response.data[0])
          //   }
          // })
        }
        
      })
    },
    searchSupport(account_id: string,path: string): Promise<any[]> {
      if (!path) {
        console.error('Required support url path missing', account_id, path);
        return Promise.reject('Required support url path missing');
      }

      if (! path.startsWith(SupportTarget.BASE_URL)) {
        console.warn('Url path ('+path+') doesn\'t start with '+SupportTarget.BASE_URL);
        //patch support path
        if (path.startsWith('/support'))
          path = '/reports'+path;
        else
        return Promise.reject('Url path ('+path+') doesn\'t start with '+SupportTarget.BASE_URL);
      }
      
      return new Promise((resolve, reject) => {
          const _url = `${serverUrl}${path}`;
          console.log('QUERY SUPPORT:',_url);
          fetch(_url, {
            headers: headers(),
            // mode: 'no-cors'
            // method: 'POST',
            // body: JSON.stringify({}),
            //credentials: 'same-origin' 
          })
          .then(res => res.json())
          .then(async response => {
            // patch some empty result responses comming as errors
            if (!response.status && (!response.data || (isArray(response.data) && response.data.length == 0) || (typeof response.data === 'object' && Object.entries(response.data).length == 0)) 
              && response.msg.toLowerCase().startsWith("sin resultados")) {
              response.status = true; 
              response.data = []; 
            }
            if (!response.status) {
              reject('Bad request')
              console.error('Bad request',response.msg);
            } else {
              let _data: any = (response.data && response.data.length == 1) ? response.data[0] : response.data;
              if (_data?.object_id !== undefined) { _data = {default: _data}; }
              console.log('Success SUPPORT request',_data);
              this.supportData[account_id] = _data;
              resolve(this.supportData[account_id]);
            }
          })
          .catch(reason => {
            reject('Bad request - error')
            console.error('Bad request - error',reason);
          });
        }  
      )
    },
    SET_ACCOUNT_RESULT(payload: any) {
      let obj: UserApiExtendedData = {//}
        // Extended data
        pinned:false,
        
      // obj.account = {
        id: payload.id || payload.id_account,
        id_account: payload.id_account || payload.id,
        email: payload.email,
        username: payload.username,
        nomail: payload.nomail,
        // created_at: payload.created_at,
        access_failed: payload.access_failed,
        tutorial: payload.tutorial,
        ext: payload.ext,
        status: payload.status,
        // user: payload.user
      // }

      // obj.profile = {
        id_profile: payload.id_profile,
        // id_account: payload.id_account,
        name: payload.name,
        last_name: payload.last_name,
        birthday_date: payload.birthday_date,
        address: payload.address.address || payload.address,
        city: payload.address.city || payload.city,
        zip_code: payload.address.zip_code || payload.zip_code,
        phone_number: payload.phone_number,
        phone_number_confirmed: payload.phone_number_confirmed,
        avatar: payload.avatar,
        avatarHtml: `<img src="${process.env.VUE_APP_IMAGES + (payload?.avatar !== undefined ? payload.avatar : 'unknown.jpg')}" width="45" height="45">`,
        profile_type: payload.current_profile?.profile_type || payload.profile_type,
        card_type_id: payload.id_card_type,
        card_type: payload.card_type,
        card_id: payload.card_id,
        company_name: payload.company_name,
        company_code: payload.company_code,
        region: payload.region,
        created_at: payload.created_at,
        updated_at: payload.updated_at,
        user: payload.user || payload.admin,
      }
      let existentUserData = -1;
      if (!(this.userData && this.userData.find(elem => elem.id === obj.id, existentUserData))) {
        this.userData.push(obj);
      } else {
        this.userData[existentUserData] = obj;
      }
    },
    clearAccount (pinned: boolean= true) {
      if (pinned)
        this.$reset();
      else
        this.userData = this.userData.filter(elem => elem.pinned == true)
      return this;
    }
  }
})