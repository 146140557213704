
import TwoColumn from "@/components/TwoColumn.vue";
import DataTable from "@/components/DataTable.vue";
import StServer from "@/components/StServer.vue";
import SupportObject from "@/components/SupportObject.vue";
import SearchIterator from "@/components/SearchIterator.vue";
import { account, profile } from "../models/account";
import { Component, computed, defineComponent, ref } from 'vue';

import { useAccountStore } from '@/stores/accountProfile'
import { ApiStatusResponse, fieldData, ServerStatusResponse } from "@/types";


export default defineComponent({
    name: "ReportView",
    components: {TwoColumn, DataTable, StServer, SupportObject},
    data() {
      return {
        loaded: false,
        loading: false,
        dataSearch: "https://mocki.io/v1/bea8841b-9eeb-4ec6-a22b-4eb44e3a9d11",
        fields: [] as fieldData[],
        userSearch: null,
        colores: ["red", "blue", "green", "yellow", "pink", "orange"],
        urlPRO: "https://api.paytiptap.com/status",
        urlSTG: "https://stg-api.paytiptap.com/status",
        elements: [] as any[],
        datos: [] as any[],
        stg: {data: undefined} as ApiStatusResponse,
        prd: {data: undefined} as ApiStatusResponse,
      }
    },
    
    methods: {
      // buscarUsuario() {
      //   fetch(this.dataSearch) //this must change by real url and /this.userSearch
      //     .then((res) => res.json())
      //     .then((res) => {
      //       const keys = Object.keys(res);

      //       for (const key in keys) {
      //         const color =
      //           this.colores[Math.floor(Math.random() * this.colores.length)];
      //         const part = keys[key];
      //         let _d = res[part];

      //         /** REMAP ACCOUNT Y PROFILE  */
      //         if (part == "account")
      //           this.addData(TwoColumn, part, account(_d), color);
      //         if (part == "profile")
      //           this.addData(TwoColumn, part, profile(_d), color);

      //         /** NO MOSTRAR DATOS DE VERSIONES */
      //         if (part == "version") continue;

      //         if (part == "guardian") {
      //           this.addData(TwoColumn, "BALANCE", res[part].balance, color);
      //           this.addData(
      //             DataTable,
      //             "CREDITS CARDS",
      //             res[part].credit_cards,
      //             color
      //           );
      //           this.addData(
      //             DataTable,
      //             "TRANSACCIONES",
      //             res[part].transactions,
      //             color
      //           );
      //           this.addData(DataTable, "ALERTAS", res[part].alerts, color);
      //         }
      //       }
      //     });
      // },
      searchAccount() {
        this.loading = true
        this.loaded = false
        this.accountProfileStore
          .clearAccount(false)
          .searchAccount(this.userSearch)
          .then((userData) => {
            // this.userData = userData;
            console.log('STORE USERDATA:',this.userData);
            // this.addData(TwoColumn, "account", account(this.userData[0]), "yellow");
            // this.addData(TwoColumn, "profile", profile(this.userData[0]), "orange");
            const searchElement = "Accounts";
            const searchComponent = this.fields.find(elem => (elem.title === searchElement));
            if (!searchComponent)
              this.addData(SearchIterator, searchElement, this.userData, "green");
            else
              searchComponent.data = this.userData;
            // for (const key in this.userData) {
            //   if (key == "account")
            //     this.addData(
            //       TwoColumn,
            //       key,
            //       account(this.userData[key]),
            //       "yellow"
            //     );

            //   if (key == "profile")
            //     this.addData(
            //       TwoColumn,
            //       key,
            //       profile(this.userData[key]),
            //       "orange"
            //     );
            // }
          })
          // .finally(() => this.fields = [])
          .catch((err: Error) => console.log(err))
          .finally(() => {
            this.loading = false
            this.loaded = true
          });
      },
      addData(layout: Component, title: string, data: any, color: string) {
        const toAdd: fieldData = { type: layout, title: title, data: data, color: color };
        this.fields.push(toAdd);
      },
      getServerStatus(url: string, server: string) {
        this.elements = [];
        this.datos = [];
        fetch(url)
          .then((res) => res.json())
          .then((res) => {
            if (server == "STG") {
              this.stg = {data: undefined};
            } else {
              this.prd = {data: undefined};
            }
            if (res.status === true && res.data) {
              const agregar: ApiStatusResponse = { 
                data: res.data,
                status: res.status,
                msg: res.msg,
              };
              //Pendiente de mejora: Actualizar solo el valor de cada key
              if (server == "STG") {
                this.stg = Object.assign({}, this.stg, agregar); // assigning this way to produce Vue FE refresh
              } else 
                this.prd = Object.assign({}, this.prd, agregar); // assigning this way to produce Vue FE refresh
            }
          })
          .catch(reason => {
            if (server == "STG") {
              this.stg = {data: (this.stg?.data as any[]).map((item:any) => {item.status = {status: null, data: "", msg: "Unknown status"}; return item;}), status: false, msg: 'Error getting server status'};
            } else {
              this.prd = {data: (this.prd?.data as any[]).map((item:any) => {item.status = {status: null, data: "", msg: "Unknown status"}; return item;}), status: false, msg: 'Error getting server status'};
            }
            console.error(`Error getting server status from ${server}`,reason);
            return `Error getting server status from ${server}`;
          });
      },
      getAllServerStatus() {
        this.getServerStatus(this.urlPRO, "PRO");
        this.getServerStatus(this.urlSTG, "STG");
      },
    },
    setup() {
      const accountProfileStore = useAccountStore();
      const userData = computed(() => accountProfileStore.userData);
      return {
        accountProfileStore,
        userData,
      }
    },
    mounted() {
      this.getServerStatus(this.urlPRO, "PRO");
      this.getServerStatus(this.urlSTG, "STG");
      setInterval(this.getAllServerStatus, 30000);
    },
});

