
  import { ApiStatusResponse, ServerStatusResponse } from '@/types';
  import { computed, defineComponent } from 'vue';
  
  const serverStatusResponseArray = Array<ServerStatusResponse>();

  export default defineComponent({
      name: "StServer",
      props: {
          title: {
              type: String,
              required: false
          },
          fullTitle: {
              type: String,
              required: false,
          },
          color: {
              type: String,
              required: false,
          },
          apisData: {
              type: [ApiStatusResponse, Object],
              required: true,
          },
      },
      computed: {
        globalStatus(): boolean {
          return this.apisData?.status !== true || !((this.apisData.data as ServerStatusResponse[]).find((elem) => (elem?.status?.status !== true)));
        },
        firstErrorMessage(): string {
          return (this.apisData?.status !== true) ? (this.apisData?.msg || 'Status request failing')
          : ((this.apisData.data as ServerStatusResponse[]).find((elem) => (elem?.status?.status !== true))?.status?.msg || '');
        },
        allErrorMessage(): string[] {
          return (this.apisData?.status !== true) ? (this.apisData?.msg || 'Status request failing')
          : ((this.apisData.data as ServerStatusResponse[]).filter((elem) => (elem?.status?.status !== true)).map((elem: ServerStatusResponse) => (`[${this.extraerTitulo(elem.api)}] ${elem.status?.msg}`)) || []);

        },
      },
      methods: {
          extraerTitulo(cadena: string) {
              if (!cadena) return 'Unknown';
              if (cadena.indexOf(":89") > 0) return "Account";
              if (cadena.indexOf(":85") > 0) return "Guardian";
              if (cadena.indexOf(":81") > 0) return "Fingerprint";
              if (cadena.indexOf(":82") > 0) return "Institution";
              if (cadena.indexOf(":86") > 0) return "Token";
              if (cadena.indexOf(":87") > 0) return "Cloudmessaging";
              if (cadena.indexOf(":88") > 0) return "System";
              if (cadena.indexOf(":83") > 0) return "Loyalty";
              if (cadena.indexOf(":84") > 0) return "Payment";
              if (cadena.indexOf(":91") > 0) return "School";
              if (cadena.indexOf(":92") > 0) return "DigitalPayment";
          },
      },
});
