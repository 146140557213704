import App from './App.vue'
import { createApp, markRaw } from 'vue'
import { createPinia } from 'pinia'
const piniaStore = createPinia();
import { default as router} from './router'

piniaStore.use(({ store }) => {
  store.$router = markRaw(router)
});
// import vuetify from './plugins/vuetify'
// import { loadFonts } from './plugins/webfontloader'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as extraComponents from 'vuetify/labs/VDataTable'
import * as directives from 'vuetify/directives'
const vuetify = createVuetify({
  components: {...components,...extraComponents},
  directives,
})

// loadFonts();


// I18n
import { createI18n } from 'vue-i18n';
// import esMessages from 'vee-validate/dist/locale/es';
// Vue.use(VueI18n);
const i18n = createI18n({
  locale: "es",
});

// VeeValidate
// import VeeValidate from 'vee-validate';

createApp(App)
  .use(i18n)
  // TODO: enable validation with Vee (currently with Vuetify native)
  // .use(VeeValidate, { 
  //   // i18nRootKey: 'validations',
  //   i18n,
  //   dictionary: {
  //     es: {
  //       messages: i18n.global.messages,
  //       attributes: { name : "nombre", last_name : "apellido" }
  //     } 
  //   },
  //   inject: true,
  // })
  .use(piniaStore)
  .use(vuetify)
  .use(router)
  .mount('#app')
