<template>
  <div>
    <h2 class="font-weight-black px-4 py-2 mt-4 text-decoration-underline text-center rounded-t-xl" :class="color">{{ title | capitalize }}</h2> 
    <v-table fluid class="grey lighten-1 rounded-xl mt-4 mb-2 pa-4">
        <template v-slot:default>
            <thead>
              <tr>
                <th v-for="el in Object.entries(data[0])" :key="el.id">
                  {{ el[0] }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="el in Object.entries(data)" :key="el.id">
                <td v-for="d in el[1]" :key="d.id">{{ d }}</td>
              </tr>
            </tbody>
        </template>
    </v-table>
  </div>
</template>
<script>
export default {
    name: "DataTable",
    props: ["data", "title"],
}
</script>