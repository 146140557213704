
    import { defineComponent } from 'vue';
    import moment from 'moment';
    
    const components:Set<any> = new Set();
    // Force update every component at the same time periodically
    setInterval(() => {
    for (const comp of components) {
        comp.$forceUpdate();
    }
    }, 60 * 1000);
  
    export default defineComponent({
        name: "FromNow",
        props: {
            date: [String,Date], 
            format: {
                type: String,
                required: false,
                default: 'dddd, MMMM Do YYYY, H:mm:ss',
            },
            lang: {
                type: String,
                required: false,
                default: 'en', //es
            },
        },
        
        created() {
            components.add(this);
        },
        
        destroyed() {
            components.delete(this);
        },
        
        computed: {
            text(): string {
                return this.value?.fromNow() || '';
            },
            
            title(): string {
                return this.value?.format(this.format) || '';
            },
        },
        setup(props) {
            return {
                value: moment(props.date,undefined,props.lang,true),
            }
        },
  });
  