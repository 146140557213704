import { toDisplayString as _toDisplayString, mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tooltip = _resolveComponent("v-tooltip")!

  return (_openBlock(), _createBlock(_component_v_tooltip, { text: _ctx.title }, {
    activator: _withCtx(({ props }) => [
      _createElementVNode("span", _mergeProps({ title: _ctx.title }, props), _toDisplayString(_ctx.text), 17, _hoisted_1)
    ]),
    _: 1
  }, 8, ["text"]))
}