import axios from "@/http/axios"
import { useUserSessionStore } from "@/stores/userSession"

// Token Refresh
let isAlreadyFetchingAccessToken = false
let subscribers: any[] = [];

function onAccessTokenFetched(access_token: any) {
  subscribers = subscribers.filter(callback => callback(access_token))
}

function addSubscriber(callback: Function) {
  subscribers.push(callback)
}

export default {
  init() {
    var store = useUserSessionStore();
    
    axios.interceptors.response.use((response: any) => {
      return response
    }, function (error: {config: any, response: any}) {
      // const { config, response: { status } } = error
      const { config, response } = error
      const originalRequest = config

      // if (status === 401) {
      if (response && response.status === 401) {
        if (!isAlreadyFetchingAccessToken) {
          isAlreadyFetchingAccessToken = true
          store.fetchAccessToken()
            .then((access_token: string) => {
              isAlreadyFetchingAccessToken = false
              onAccessTokenFetched(access_token)
            })
        }

        const retryOriginalRequest = new Promise((resolve) => {
          addSubscriber((access_token: string) => {
            originalRequest.headers.Authorization = 'Bearer ' + access_token
            resolve(axios(originalRequest))
          })
        })
        return retryOriginalRequest
      }
      return Promise.reject(error)
    })
  },
  login(email: string, pwd: string) {
    var store = useUserSessionStore();
    // return axios.post("/api/auth/login", {email: email, password: pwd})
    return axios.post("/reports/support/login", {email: email, password: pwd,},{
      headers: { 
        'x-secure-identity': store.GET_FINGERPRINT(),
      },
    })
  },
  // registerUser(name: string, email: string, pwd: string) {
  //   return axios.post("/api/auth/register", {displayName: name, email: email, password: pwd})
  // },
  refreshToken() {
    var store = useUserSessionStore();
    return axios.post("/api/auth/refresh-token", {accessToken: localStorage.getItem("accessToKen")},{
      headers: { 
        'x-secure-identity': store.GET_FINGERPRINT(),
      },
    });
  }
}
