// Pinia Store
import { PartialType } from '@/types';
import { defineStore } from 'pinia'
import jwt from "@/http/requests/auth/jwt"
import axios from '@/http/axios';
import { useRouter, useRoute, Router } from 'vue-router';
import { RemovableRef, useStorage } from '@vueuse/core'

export interface SessionPreference {
  name: string;
  status: string | boolean | number;
  defaultStatus: string | boolean | number;
  changesCount: number;
  lastChange: Date;
  /** Is global preference, not only for global session */
  global: boolean;
};

export class defaultSessionPreference implements PartialSessionPreference {
  constructor(
    public name: string,
    public status: string | boolean | number,
    public global: boolean,
    public defaultStatus?: string | boolean | number,
    public changesCount: number = 0,
    public lastChange: Date = new Date()
  ) { if (defaultStatus === undefined) this.defaultStatus = status; };
};

export type PartialSessionPreference = PartialType<SessionPreference>;

export class SessionUserAuth {
  constructor(
    public token?: string,
    public expire?: any,
    // public authorization?: string,
    public x_secure_identity?: string
  ) {}
}

export class SessionUser {
  constructor(
    public name: object,
  ) {}

  static getExternalUrl(state: State) {
    return (key:any) => {
      const url = state.AppData.externalUrls?.find((u:any) => u.key == key)
      if (!url) return false
      return url.protocol + '://' + url.subdomain + url.domain;
    }
  }
}

export interface State {
  Prefs: SessionPreference[] | RemovableRef<SessionPreference[]>,
  AppData: any,
  AppActiveUser: any,
  accessToken?: any,
  auth: SessionUserAuth | RemovableRef<SessionUserAuth>,
  $router: Router,
}

const STORE_NAME = 'userSession';

export const useUserSessionStore = defineStore(STORE_NAME, {
  state: (): State => ({
      auth: useStorage(`${STORE_NAME}_auth`, new SessionUserAuth(),localStorage,{mergeDefaults: true,}),
      Prefs: useStorage(`${STORE_NAME}_Prefs`, [],localStorage,{mergeDefaults: true,}),
      AppData: useStorage(`${STORE_NAME}_AppData`, {},localStorage,{mergeDefaults: true,}),
      AppActiveUser: useStorage(`${STORE_NAME}_AppActiveUser`, {},localStorage,{mergeDefaults: true,}),
      $router: {} as Router,
  }),
  getters: {
    changed(state) {
      return state.Prefs.filter(elem => elem.status !== elem.defaultStatus);
    },
    global(state) {
      return state.Prefs.filter(elem => elem.global);
    },
    running(state) {
      return state.Prefs.filter(elem => !elem.global);
    },
    lastChanged(state) {
      return state.Prefs.reduce(function(prev, curr){ return  (prev && prev.lastChange > curr.lastChange ? prev : curr)});
    },

    expire(state) {
      return state.auth?.expire ? state.auth?.expire : Date.now() / 1000 - 300;
    },
    
    isUserLoggedIn(state) {
      if (!state.auth?.token) { return false; }
      if (!state.auth?.expire) { return false; }
  
      let isAuthenticated = true;
      
      let exp = state.auth.expire;
      if (new Date(Date.now()) >= new Date(exp * 1000)) { isAuthenticated = false; }
  
      return isAuthenticated;
    },
  },
  actions: {
    setPreference(): Promise<SessionPreference> {
      return new Promise((resolve, reject) => {
      });
    },
    /** Return all statuses to default */
    restoreDefaultPreferences() {
      const now = new Date();
      // overrides array on porpouse, to force UI refresh
      this.Prefs = this.Prefs.map(elem => ({...elem, ...{
        status: elem.defaultStatus,
        lastChange: now,
        changesCount: 0,
      }
      }));
      return this;
    },

    // JWT
    loginJWT(payload : any) {
      const { email, password } = payload;

      return new Promise((resolve,reject) => {
        
        jwt.login(email, password)
          .then(async (response: any) => {
            // If there's user data in response
            if (response.data.status) {

              if(response.data.data.account) {
                  // Set bearer token in axios
                  this.SET_BEARER(response.data.data.auth.token);
                  this.SET_EXPIRE(response.data.data.auth.expire);
                  this.SET_FINGERPRINT(response.data.data.auth.fingerprint);

                  //Status 4 = Not Verified => First Login
                  if (response.data.data.account.status == 4) {
                    this.UPDATE_USER_INFO({email: response.data.data.account.email, status: response.data.data.account.status})
                    return resolve(response.data.data)
                  }
    
                  // Update user details
                  this.UPDATE_USER_INFO(response.data.data)

                  //Pending: get appData from API CALL
                  let appData = { profileTypes : [
                    { profile_type : 1, profile_type_description : "Acudiente", role: "guardian" },
                    { profile_type : 2, profile_type_description : "Estudiante", role: "student" },
                    { profile_type : 3, profile_type_description : "Admin Cafetería", role: "owner" },
                    { profile_type : 4, profile_type_description : "Gerente", role: "manager" },
                    { profile_type : 5, profile_type_description : "Cajero", role: "seller" },
                    { profile_type : 6, profile_type_description : "Admin Escuela", role: "school" },
                    { profile_type : 10, profile_type_description : "Admin País", role: "admin" },
                    { profile_type : 11, profile_type_description : "Super Admin", role: "superadmin" },
                    { profile_type : 12, profile_type_description : "Support", role: "support" },
                  ]}

                  this.UPDATE_APP_DATA(appData);

                  // Set user role
                  // dispatch('updateUserRole', {userRole: response.data.data.profile.profile_type},{ root: true })

                  // Navigate User to homepage
                  console.log(this.$router.getRoutes())
                  this.$router.push({name: 'report'}) //router.currentRoute.query.to || 
                  // await this.$router.push('/report') //router.currentRoute.query.to || 
    
                  resolve(response.data.data)
                } else {
                  reject({msg: response.data.msg})
                }
            } else {
                reject({msg: response.data.msg})
            }
          })
          .catch((error: any) => {
            if (error?.data?.msg)
              reject({msg: error.data.msg});
            else
              reject(error)
          })
      })
    },

    fetchAccessToken() {
      return new Promise<any>(async (resolve) => {
        return jwt.refreshToken().then((response: any) => { resolve(response) })
      })
    },
    setFingerprint(identity: any) {
      this.SET_FINGERPRINT(identity);
    },
    logout(redirect=true) {
      const router = useRouter();
      // JWT login
      if(this.auth?.token) {
        delete this.auth.token;
      }
      // Change role on logout. Same value as initialRole of acj.js
      // this.$acl.change('admin')

      this.SET_EXPIRE(null);
      this.AppActiveUser = {};
      this.Prefs = [];

      this.LOGOUT(['auth','AppActiveUser',]);

      if (redirect) router.push('/login').catch(() => {})
    },
    
    // /////////////////////////////////////////////
    // User/Account
    // /////////////////////////////////////////////

    // Updates user info in state and localstorage
    UPDATE_USER_INFO(payload: any) {

      for (const property of Object.keys(payload)) {

        if (payload[property] != null) {
          // If some of user property is null - user default property defined in state.AppActiveUser
          this.AppActiveUser[property] = payload[property]
        }
      }
    },

    SET_TOKEN() {
      let authorization = this.auth?.token ? `Bearer ${this.auth.token}` : false ;
      let fingerprint = this.auth?.x_secure_identity || false ;

      axios.defaults.headers.common['Authorization'] = authorization
      axios.defaults.headers.common['x-secure-identity'] = fingerprint;
    },
    //AppData
    SET_APP_DATA(appData: any) {
      // let appData = JSON.parse(appData)  //Pending: implement appData
      this.AppData = appData;
    },

    UPDATE_APP_DATA(payload: any) {

      for (const property of Object.keys(payload)) {

        if (payload[property] != null) {
          // If some of user property is null - user default property defined in state.appData
          this.AppData[property] = payload[property]
        }
      }
    },

    LOGOUT(list: string[]) {
      for (const property of Object.keys(this)) {
        
        if ((this.$state as any)[property] != null && list.includes(property)) { 
          // store.unregisterModule(property)
          (this.$state as any)[property] = {}
        }
      }
    },

    SET_BEARER(accessToken: string) {
      if (this.auth) {
        this.auth.token = accessToken;
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.auth.token}`;
        return true;
      }
      return false;
    },

    SET_EXPIRE(date: any) {
      if (this.auth) { 
        this.auth.expire = date; 
        return true;
      }
      return false;
    },

    GET_FINGERPRINT() {
      if (this.auth?.x_secure_identity) { 
        return this.auth.x_secure_identity;
      }
      return '1234';
    },

    SET_FINGERPRINT(fingerprint: string) {
      if (this.auth) { 
        this.auth.x_secure_identity = fingerprint;
        axios.defaults.headers.common['x-secure-identity'] = fingerprint;
        return true;
      }
      return false;
    },

    
    onBeforeRouteUpdate(to: any, from: any) {
    
      axios.interceptors.response.use((response: any) => {
        if(response && (response.status == 402 || response.status == 403)){
          this.logout();
          return;
        }
        return response;
      });
    
      if (to.path === '/logout') {
        this.logout();
      } else {
        if (to.matched.some((record: any) => record.meta.requiresAuth)) {
      
          if (to.name !== 'login' && !this.isUserLoggedIn) {
            this.logout(false);
            return {
              name: 'login',
              replace: true
            }
          }
        } else {
          if (to.name == 'login' && this.isUserLoggedIn)
            return {
              name: 'report'
            }
        }
      }
    
    },
  },  
});
