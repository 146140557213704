
import { defineComponent } from "vue"
import FromNow from "./FromNow.vue";
import TargetField from "./TargetField.vue";
import { isValidDate, isArray, toCamelCase } from "../plugins/common";
import SupportTarget from "@/types/SupportTarget";

const MAX_DEEP_LEVEL = 5;

export default defineComponent({
    name: "TwoColumn",
    props: {
        inputData: Object, 
        title: String,
        color: String,
        isVisibleItem: {
            type: Function,
            default: () => ((k:any) => true),
        },
        deepLevel: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    components: {FromNow,},
    emits: ['expand'],
    data() {
        return {
            expanded: false,
            MAX_DEEP_LEVEL: MAX_DEEP_LEVEL,
        }
    },
    computed: {
        targets(): SupportTarget[] {
            return (this.inputData && this.inputData.object_extension)? this.inputData['object_extension'] : [];
        }
    },
    methods: {
        isValidDate,
        isArray,
        toCamelCase,
        targetClick(e:string, target: SupportTarget){
            console.log('clicked target', target);
        }
    }
});
